import React from "react";
import {Box, Button, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";

export const FirstBox = styled(Box)(({theme}) => ({
	display: 'flex',
	marginTop: '30px',
	borderBottom: '2px solid #E6E6E6',
	paddingBottom: '20px'
}));
export const InformationField = styled(TextField)(({theme}) => ({
	width: '405px', height: '52px', marginTop: '10px'
}));

export const PasswordBox = styled(Box)(({theme}) => ({
	display: 'flex', marginTop: '30px', borderBottom: '2px solid #E6E6E6', paddingBottom: '20px',
}));
export const PasswordField = styled(TextField)(({theme}) => ({
	width: '405px', height: '52px', marginTop: '10px'
}));
export const PriceButton = styled(Button)(({theme}) => ({
	width: '147px',
	height: '52px',
	marginTop: '50px',
	borderRadius: '4px',
	background: '#0037FF',
	'&:hover': {
		boxShadow: 'none', background: '#0037FF',
	},

}));
