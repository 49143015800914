import React, {useEffect} from "react";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getReq} from "../utils/rest";
import API from "../utils/api";
import {Box} from "@mui/material";
import {Counter, CounterButton, IconContainer, MyAccordion, Row, Title} from "./CollapseRow.style";

const CollapseRow = ({activeCategory, setActiveCategory, calculatePrice, data}) => {
	const [categories, setCategories] = React.useState([]);
	const [productList, setProductList] = React.useState([]);

	const increase = (_item) => {
		calculatePrice(_item, true)
	}

	const decrease = (_item) => {
		calculatePrice(_item, false)
	}

	useEffect(() => {
		getReq(API.categories, false)
			.then((_data) => {
				setCategories(_data?.data);
			})
		getReq(API.productsBase, false)
			.then((_data) => {
				setProductList(_data?.data);
			}).catch((e) => {
		})
	}, [])

	const renderRow = (item) => {
		let count = 0;
		const $id = item?.id;
		const $oldObj = data?.products?.filter((t) => t.id === $id);
		if ($oldObj?.length > 0) {
			count = $oldObj[0].count
		}
		const title = item?.attributes?.title || item?.item?.item?.attributes?.title || item?.item?.attributes?.title;
		const description = item?.attributes?.description || item?.item?.item?.attributes?.description || item?.item?.attributes?.description;
		return <Row sx={{marginTop: '20px'}}>
			<div>
				<Typography sx={{fontWeight: '600', fontSize: '14px'}}>{title}</Typography>
				<Typography sx={{fontWeight: '500', fontSize: '14px', color:'#9e9d9f'}}>{description}</Typography>
			</div>
			<Row sx={{justifyContent: 'center'}}>
				<CounterButton className={'noselect'} onClick={() => decrease(item)}>
					<img width={'12px'}
						 src={require('../assets/remove.png')}/></CounterButton>
				<Counter className={'noselect'}>{count}</Counter>
				<CounterButton className={'noselect'} onClick={() => increase(item)}>
					<img width={'12px'}
						 src={require('../assets/add.png')}/></CounterButton>
			</Row>
		</Row>
	}


	return <div>
		{categories.map((item, index) => {
			const filteredProductList = productList?.filter((e) => {
				return e?.attributes?.category?.data?.id === item?.id
			})
			let totalCount = 0;

			for (const filteredProductListElement of filteredProductList) {
				const $oldObj = data?.products?.filter((t) => t.id === filteredProductListElement?.id);
				if ($oldObj?.length > 0) {
					totalCount += $oldObj[0].count
				}
			}
			return <MyAccordion sx={{
				marginBottom: '10px',
				background: '#F7F7F7',
				boxShadow: 'none',
				borderTopLeftRadius: '10px !important',
				borderTopRightRadius: '10px !important',
				borderBottomRightRadius: '10px !important',
				borderBottomLeftRadius: '10px !important',
				border: 'none'
			}}
								expanded={activeCategory === item?.id}
								key={index}
								onChange={() => {
									if (activeCategory === item?.id) {
										setActiveCategory(null)
									} else {
										setActiveCategory(item?.id)
									}
								}}>
				<AccordionSummary
					sx={{
						borderBottomRightRadius: '10px !important',
						borderBottomLeftRadius: '10px !important',
						border: 'none'
					}}
					expandIcon={<ExpandMoreIcon/>}
					aria-controls={'panel1a-content-' + index}
					id={'panel1a-header-' + index}>
					<IconContainer>
						<Box sx={{
							background: activeCategory === item?.id ? '#0037FF' : '#575A66',
							width: '40px',
							height: '40px',
							borderRadius: '8px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
							<Box width={'15px'} component={'img'} src={item?.attributes?.icon?.data?.attributes?.url}/>
						</Box>
					</IconContainer>
					<Title
						sx={{color: activeCategory === item?.id ? '#212226' : '#575A66'}}>{item?.attributes?.title} {totalCount ? `(${totalCount})` : null} </Title>
				</AccordionSummary>
				<AccordionDetails sx={{background: '#ffffff', paddingBottom: '20px'}}>
					{filteredProductList.map((data) => {
						return renderRow(data);
					})}
				</AccordionDetails>
			</MyAccordion>
		})}
	</div>
}

export default CollapseRow;
