import {Box, Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {ListBox, MainBox, SecondBox} from "./ProposalList.style";
import {useHistory, useLocation} from "react-router-dom";
import {deleteReq, getReq} from "../utils/rest";
import API from "../utils/api";
import getFormattedTime from "../utils/dateTime";
import queryString from "query-string";
import {FlexContainerLg} from "./Common.styles";
import localforage from "localforage";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {toast} from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import {getCurrentFormat} from "../utils/currency";

const MyProposal = () => {
	const {search} = useLocation()
	const values = queryString.parse(search)
	const history = useHistory();
	const [myProposals, setMyProposals] = React.useState([]);
	const [filteredProposals, setFilteredProposals] = React.useState([]);
	const [isLoading, setLoading] = React.useState(true);

	useEffect(() => {
		readUserFromDb()
	}, [])

	const readUserFromDb = async () => {
		const userStr = await localforage.getItem('user')
		if (userStr) {
			const userJSON = JSON.parse(userStr);
			getMyProposals(userJSON?.id)
		}
	}

	useEffect(() => {
		const search = values?.q;
		if (search?.length === 0) {
			setFilteredProposals(myProposals);
			return;
		}
		let rr = myProposals.filter((k) => {
			return k?.attributes?.title?.toUpperCase()?.includes(search?.toUpperCase()) || k?.attributes?.description.toUpperCase()?.includes(search?.toUpperCase()) || k?.attributes?.brand?.data?.attributes?.title?.toUpperCase().includes(search.toUpperCase())
		})
		setFilteredProposals(rr);
	}, [values.q])

	const select = (data) => {
		history.push(`proposal-detail/${data?.id}`, {
			data: data
		})
	}

	const getMyProposals = (userId) => {
		getReq(API.myProposals + userId, false)
			.then((_data) => {
				setMyProposals(_data?.data);
				setFilteredProposals(_data?.data)
				setLoading(false)
			})
	}

	const removeItem = (e, id) => {
		e.stopPropagation();
		setLoading(true);
		deleteReq(API.deleteProposal + id, false)
			.then((_data) => {
				toast.success('Proposal has been deleted')
				readUserFromDb()
			}).catch((e) => {
			setLoading(false)
		})
	}


	return (<FlexContainerLg>
		<Grid container spacing={4} maxWidth={'lg'} sx={{marginTop: 0, paddingTop: '4px !important'}}>
			<Grid item lg={8} md={12} xs={12} sx={{paddingTop: '2px !important'}}>
				<ListBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px'
					}}>My Proposals</Typography>
					<Typography sx={{
						position: 'relative', left: '4px', color: '#0037FF', fontWeight: '700', fontSize: '18px',
					}}>({myProposals?.length})</Typography>
				</ListBox>
				{!isLoading ? filteredProposals.map((item, index) => {
					return <MainBox key={index} onClick={() => select(item)} sx={{cursor: 'pointer'}}>
						<SecondBox>
							<Box sx={{ml: '15px', mt: '20px'}}>
								<Typography sx={{
									fontWeight: '700', fontSize: '15px', color: '#575A66'
								}}>{item?.attributes?.title}</Typography>
								<Typography sx={{
									fontWeight: '600', fontSize: '13px', color: '#212226'
								}}>{item?.attributes?.brand}</Typography>
								<Typography sx={{
									fontWeight: '500', fontSize: '9px', color: '#575A66', paddingTop:'4px'
								}}>{getFormattedTime(item?.attributes?.createDate)}</Typography>
							</Box>
						</SecondBox>
						<Box sx={{mt: '20px', flexDirection: 'row', display: 'flex', paddingTop: '10px'}}>
							<Typography sx={{
								fontWeight: '700', fontSize: '16px', color: '#212226'
							}}>{getCurrentFormat(item?.attributes.data).format(item?.attributes?.data?.total)}</Typography>
							<HighlightOffIcon sx={{marginLeft: '20px', color: '#ff000080'}}
											  onClick={(e) => removeItem(e, item?.id)}/>
						</Box>
					</MainBox>
				}) : <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px'}}>
					<CircularProgress color="primary"/>
				</Box>}
			</Grid>
		</Grid>
	</FlexContainerLg>)


}
export default MyProposal;
