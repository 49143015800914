import React from "react";
import Header from "../components/Header";
import Deliverables from "../components/Deliverables";

const CreateProposalFirst = () => {
	return (<>
		<Header/>
		<Deliverables/>
	</>);
}

export default CreateProposalFirst;
