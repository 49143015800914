import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import axios from "axios";
import API from "../utils/api";
import validateEmail from "../utils/email";
import localforage from "localforage";
import {useHistory} from "react-router-dom";

const useLoginPage = () => {
	const [welcome, setWelcome] = useState(null);
	const [mobileApps, setMobileApps] = useState([]);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		checkToken()
		getMobileApps()
		getLoginPage()
	}, []);

	const checkToken = async () => {
		const token = await localforage.getItem('token')
		if (token) {
			history.push("/homepage");
		}
	}

	const getLoginPage = async () => {
		const response = await axios.get(API.welcome);
		if (response.status === 200) {
			setWelcome(response.data.data[0]);
		} else {
			toast.error("Email or password is incorrect")
		}
	}

	const getMobileApps = async () => {
		const response = await axios.get(API.mobileApps);
		if (response.status === 200) {
			setMobileApps(response.data.data || []);
		} else {
			toast.error("Email or password is incorrect")
		}
	}

	const emailChanged = (event) => {
		setEmail(event.target.value);
	}

	const passwordChanged = (event) => {
		setPassword(event.target.value)
	}


	const clickHandler = () => {
		if (!validateEmail(email.trim())) {
			toast.error('Please enter a valid e-mail')
			return false;
		}
		if (password.length < 6) {
			toast.error('Please enter a valid password')
			return false;
		}
		makeLoginRequest()
	}


	const myUserFromServer = async (token) => {
		const response = await axios.get(API.getMyUserDetail + email, {
			headers: {
				'Authorization': token
			}
		})
		if (response.status === 200) {
			await localforage.setItem('user', JSON.stringify(response.data[0]))
		} else {
			toast.error('An error occurred')
		}
	}

	const makeLoginRequest = () => {
		setIsLoading(true)
		axios.post(API.login, {
			identifier: email.trim(), password: password
		}).then(async (response) => {
			setIsLoading(false)
			if (response.status === 200) {
				const token = "Bearer " + response.data.jwt;
				await localforage.setItem('token', token)
				await myUserFromServer(token);
				history.push("/homepage");
			} else {
				toast.error('Email or password is incorrect')
			}
		}).catch(() => {
			setIsLoading(false)
			toast.error('Email or password is incorrect')
		})
	}


	return {
		welcome, clickHandler, emailChanged, passwordChanged, email, password, mobileApps, isLoading
	}
}


export default useLoginPage;
