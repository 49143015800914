import axios from "axios";
import localforage from "localforage";

const getReq = async (uri, tokenRequired = true) => {
	const token = await localforage.getItem('token')
	if (token != null) {
		const response = await axios.get(uri, {
			headers: {
				'Authorization': token
			}
		});

		if (response.status === 200) {
			return response?.data
		} else {
			return null
		}
	}
	if (token != null || !tokenRequired) {
		const response = await axios.get(uri);
		if (response.status === 200) {
			return response?.data
		} else {
			return null
		}
	}
}

const putReq = async (uri,id, data, tokenRequired = true) => {
	const token = await localforage.getItem('token')
	if (token != null) {
		const response = await axios.put(uri +  id, data, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			return response?.data
		} else {
			return null
		}
	}
	if (token != null || !tokenRequired) {
		const response = await axios.put(uri, data);
		if (response.status === 200) {
			return response?.data
		} else {
			return null
		}
	}
}

const deleteReq = async (uri, tokenRequired = true) => {
	const token = await localforage.getItem('token')
	if (token != null) {
		const response = await axios.delete(uri, {
			headers: {
				'Authorization': token
			}
		});
		if (response.status === 200) {
			return response?.data
		} else {
			return null
		}
	}
	if (token != null || !tokenRequired) {
		const response = await axios.delete(uri);
		if (response.status === 200) {
			return response?.data
		} else {
			return null
		}
	}


}


export {
	getReq,
	deleteReq,
	putReq
}
