import {styled} from "@mui/material/styles";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";

export const LoginTextField = styled(TextField)(({theme}) => ({
	'@media (max-width: 550px)': {
		marginTop: '20px'
	}, ' & fieldset': {
		border: '1px solid #44A3B5', boxSizing: 'border-box', borderRadius: '4px',
	}
}));

export const LoginBox = styled(Box)(({theme}) => ({
	padding: '15px',
	marginTop: '100px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	borderRadius: 30,
	fontSize: '12px',
	'@media (max-width: 550px)': {
		marginTop: '20px',
	},
	'& a': {
		fontWeight: 700, color: '#0037FF',
	}
}));
export const PriceButton = styled(Button)(({theme}) => ({
	width: '150px', height: '50px', fontSize: '12px', fontWeight: '700', background: '#0037FF', '&:hover': {
		boxShadow: 'none', background: '#0037FF',
	}
}));
export const LoginText = styled(Typography)(({theme}) => ({}));

export const LinkContainer = styled(Grid)(({theme}) => ({
	'& a': {
		textDecoration: 'none', color: 'black',
	}
}));
