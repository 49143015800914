import Header from "../components/Header";
import React from "react";
import ProfileDetail from "../components/ProfileDetail";

const ProfilePage = () => {
    return (<>
        <Header/>
        <ProfileDetail/>
    </>);
}
export default ProfilePage;