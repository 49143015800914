import React from "react";
import {Box, TextField, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

export const MainBox = styled(Box)(({theme}) => ({
    display:'flex', justifyContent:'space-between'
}));
export const SecondBox = styled(Box)(({theme}) => ({
    display:'flex',flexDirection:'row'
}));
export const ListBox = styled(Box)(({theme}) => ({
    display: 'flex', marginTop: '50px',
    borderBottom: '2px solid #E6E6E6', paddingBottom: '20px',
}));
export const TextBox = styled(Box)(({theme}) => ({
    display: 'flex', justifyContent:'space-between',marginTop: '15px',alignItems:'center',
    borderBottom: '1px solid #F0F0F0', paddingBottom: '20px',

}));

export const TextInner = styled(TextField)(({theme}) => ({
width:'45px',
    height:'45px',
    color: '#575A66',
    borderBottom: 'none',
    '& input':{
    color:'color: #575A66',
        padding:'9px',
        background:'#F2F2F2',
        textAlign:'center',
        '& .MuiInput-underline:after': {
            borderBottomColor: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'none',
            }, '&:hover fieldset': {
                borderColor: 'none',
            }, '&.Mui-focused fieldset': {
                borderColor: 'none',
            },
        },
    }

}));
export const TextArea = styled(Typography)(({theme}) => ({
    fontWeight:'600',
    fontSize: '14px',
    color:'#575A66',
}));

export const TextArea2 = styled(Typography)(({theme}) => ({
	fontWeight:'500',
	fontSize: '14px',
	color:'#989999',
}));

export const RightBox = styled(Box)(({theme}) => ({
    display: 'flex', marginTop: '30px',
    paddingBottom: '20px',
    borderBottom: '2px solid #E6E6E6',
}));
