export const getCurrentFormat = (data) => {
	const currencyList = data?.currencyList;
	const currency = data?.currency
	let formatName = "USD";
	let formatISO = "en-US";
	if (currencyList?.length > 0 && currency) {
		const currencyData = currencyList.filter((data) => {
			return data?.attributes?.name === currency
		})

		formatName = currencyData[0]?.attributes?.name
		formatISO = currencyData[0]?.attributes?.iso
	}
	return new Intl.NumberFormat(formatISO, {
		style: 'currency', currency: formatName,
	});
}

