import {CDN_URL} from "./api";

const createCDN = (image) =>{
	return `${CDN_URL}${image}`
}

export const createCDNForImgFolder = (image) =>{
	return `${CDN_URL}img/${image}`
}

export const createCDNFromStrapi = (obj) =>{
	return `${CDN_URL}${obj?.hash}${obj?.ext}`
}


export default createCDN;
