import React from "react";
import Header from "../components/Header";
import ProposalList from "../components/ProposalList";

const MyProposal = () => {
    return (<>
        <Header/>
        <ProposalList/>
    </>);
}
export default MyProposal;
