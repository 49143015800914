import './App.css';
import LoginPage from "./pages/LoginPage";
import ForgetPassword from "./pages/ForgetPassword";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage";
import CreateProposalFirst from "./pages/CreateProposalFirst";
import CreateProposalSecond from "./pages/CreateProposalSecond";
import MyProposal from "./pages/MyProposal";
import ProposalDetail from "./pages/MyProposalDetail";
import ProfilePage from "./pages/ProfilePage";

function App() {
	return (<BrowserRouter>
			<Switch>
				<Route path="/homepage">
					<HomePage/>
				</Route>
				<Route path="/forget-password">
					<ForgetPassword/>
				</Route>
				<Route path="/create-proposal/1">
					<CreateProposalFirst/>
				</Route>
				<Route path="/create-proposal/2">
					<CreateProposalSecond/>
				</Route>
				<Route path="/my-proposals">
					<MyProposal/>
				</Route>
				<Route path="/proposal-detail/:id">
					<ProposalDetail/>
				</Route>
				<Route path="/profile">
					<ProfilePage/>
				</Route>
				<Route path="/">
					<LoginPage/>
				</Route>

			</Switch>
		</BrowserRouter>);
}

export default App;
