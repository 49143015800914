export const DOMAIN = 'https://admin.price.sharpcake.com/'
export const CDN_URL = 'https://d16sbrj48smgf9.cloudfront.net/'

const API = {
    welcome: `${DOMAIN}api/statics?filters[page][$eq]=login&populate=image`,
	forget:`${DOMAIN}api/statics?filters[page][$eq]=forget-password&populate=image`,
	vat:`${DOMAIN}api/statics?filters[page][$eq]=vat`,
	homepage: `${DOMAIN}api/static-pages?filters[page][$eq]=homepage`,
	header: `${DOMAIN}api/statics?filters[page][$eq]=header&populate=image`,
	categories: `${DOMAIN}api/categories?populate=icon`,
	myProposals: `${DOMAIN}api/proposals?sort[0]=createdAt%3Adesc&populate=products,brand,brand.logo&filters[user][id][$eq]=`,
	discounts: `${DOMAIN}api/discounts`,
	mobileApps: `${DOMAIN}api/mobile-apps`,
	currencies: `${DOMAIN}api/currencies`,
	deleteProposal: `${DOMAIN}api/proposals/`,
	proposal: `${DOMAIN}api/proposals/`,
	saveProposal: `${DOMAIN}api/proposals?populate=products,brand,brand.logo`,
	notifications: `${DOMAIN}api/notifications`,
	brands: `${DOMAIN}api/brands?populate=logo`,
    login: `${DOMAIN}api/auth/local`,
	password: `${DOMAIN}api/updatepassword`,
	getMyUserDetail: `${DOMAIN}api/users?populate=profile,title&filters[email][$eq]=`,
	forgetPassword: `${DOMAIN}api/auth/forgot-password`,
	products: `${DOMAIN}api/products?filters[category][id][$eq]=`,
	productsBase: `${DOMAIN}api/products?populate=category&pagination[page]=1&pagination[pageSize]=999`,
}

export const getHomepageAPI = (type, title)=>{
	return `${DOMAIN}api/users?populate=profile,skills,languages&filters[isFreelancer][$eq]=true&filters[${type}][title][$in]=${title}`
}

export default API;
