import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import localforage from "localforage";
import {HeadBox, TotalBox, PriceButton} from "./Deliverables.styles";
import {FlexContainerLg} from "./Common.styles";
import axios from "axios";
import API from "../utils/api";
import {getCurrentFormat} from "../utils/currency";
import {Counter, CounterButton, Row} from "./CollapseRow.style";
import calculatePrice from "../utils/priceCalculator";
import {AmountTextField, SelectControl} from "./Cart.style";
import {getReq} from "../utils/rest";

const Deliverables = () => {
	const [data, setData] = React.useState({
		categoryId: 0, total: 0, products: [], discountType: 'Amount', discount: null, oldPrice: 0
	});
	const history = useHistory();
	const [vat, setVat] = React.useState(null);
	const [items, setItems] = React.useState([]);

	let totalCount = 0;
	for (const product of data.products) {
		totalCount = totalCount + product.count;
	}

	useEffect(() => {
		getDiscounts();
	}, [])

	const getDiscounts = () => {
		getReq(API.discounts, false)
			.then((data) => {
				const _data = data?.data?.map((t) => {
					return {label: t.attributes.type, value: t.attributes.type}
				})
				setItems(_data)
			})
	}

	const calculatePricer = async (item, isIncrease) => {
		let products = data.products;
		item = item?.item;

		if (data.products.filter((l) => l.id === item.id).length > 0) {
			products = data.products.map((i) => {
				if (i.id === item.id) {
					let _count = isIncrease ? i.count + 1 : i.count - 1
					if (_count < 0) {
						_count = 0;
					}
					return {
						id: item.id, count: _count, item: item
					}
				}
				return i;
			})
		} else {
			products.push({
				id: item.id, count: isIncrease ? 1 : 0, item: item
			})
		}

		let total = 0;
		for (const product of products) {
			const price = calculatePrice(product, data?.currency)
			total = total + ((price * product?.count))
		}

		const _data = {
			...data, total: total, oldPrice: total, products: products
		};
		setData(_data)
		await localforage.setItem('cart', JSON.stringify(_data))
	}


	const increase = (_item) => {
		calculatePricer(_item, true)
	}

	const decrease = (_item) => {
		calculatePricer(_item, false)
	}


	const getFromDb = async () => {
		const _data = await localforage.getItem('cart');
		if (_data) {
			setData(JSON.parse(_data))
		}
	}
	const getVat = async () => {
		const response = await axios.get(API.vat);
		if (response.status === 200) {
			setVat(response.data.data[0]);
		}
	}

	const onDiscountChanged = async () => {
		let _total;
		if (data?.discountType === '%') {
			_total = data.oldPrice - (data?.oldPrice * data?.discount / 100)
		} else {
			_total = data.oldPrice - data?.discount;
		}
		if (_total < 0) {
			_total = 0;
		}
		const _data = {...data, total: _total, discount: data?.discount, discountType: data?.discountType};
		setData(_data)
		await localforage.setItem('cart', JSON.stringify(_data))
	}

	useEffect(() => {
		if (data?.discountType != null) {
			onDiscountChanged();
		}
	}, [data?.discount, data?.discountType, data?.oldPrice, data?.currency])


	useEffect(() => {
		getFromDb();
		getVat()

	}, [])

	const onNext = () => {
		history.push('/create-proposal/2')
	}


	const renderRow = (item) => {
		let count = 0;
		const $id = item?.id;
		const $oldObj = data?.products?.filter((t) => t.id === $id);
		if ($oldObj?.length > 0) {
			count = $oldObj[0].count
		}
		const title = item?.attributes?.title || item?.item?.item?.attributes?.title || item?.item?.attributes?.title;
		const description = item?.attributes?.description || item?.item?.item?.attributes?.description || item?.item?.attributes?.description;
		return <Row sx={{marginTop: '20px'}}>
			<div>
				<Typography sx={{fontWeight: '600', fontSize: '14px'}}>{title}</Typography>
				<Typography sx={{fontWeight: '500', fontSize: '14px', color: '#9e9d9f'}}>{description}</Typography>
			</div>
			<Row sx={{justifyContent: 'center'}}>
				<CounterButton className={'noselect'} onClick={() => decrease(item)}>
					<img width={'12px'}
						 src={require('../assets/remove.png')}/></CounterButton>
				<Counter className={'noselect'}>{count}</Counter>
				<CounterButton className={'noselect'} onClick={() => increase(item)}>
					<img width={'12px'}
						 src={require('../assets/add.png')}/></CounterButton>
			</Row>
		</Row>
	}


	return (<FlexContainerLg>
		<Grid container spacing={4} maxWidth={'lg'}>
			<Grid item md={6}>
				<HeadBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px', paddingBottom: '18px'
					}}>Deliverables</Typography>
					<Typography sx={{
						position: 'relative', left: '4px', color: '#0037FF', fontWeight: '700', fontSize: '18px',
					}}>({totalCount})</Typography>
				</HeadBox>

				{data?.products?.map((product) => {
					return renderRow(product)
				})}

				<Box sx={{
					marginTop: '50px', borderBottom: '1px solid #F0F0F0'
				}}>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px', paddingBottom: '18px'
					}}>Details</Typography>
				</Box>
				<Box sx={{marginTop: '20px'}}>
					<Typography sx={{
						color: '#5D5C66', fontWeight: '500', fontSize: '14px'
					}}>{vat?.attributes?.description}</Typography>
				</Box>

			</Grid>
			<Grid item md={5}>
				<Box sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					marginTop: '100px',
					marginLeft: {lg: '50px'}
				}}>
					<Box>
						<Typography
							sx={{color: '#848484', fontSize: '12px', fontWeight: '600', paddingBottom: '8px'}}>Discount
							type</Typography>
						{data?.discountType && <SelectControl fullWidth>
							<Select
								sx={{fontSize: '14px', color: '#575A66'}}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								onChange={(e) => {
									setData({...data, discountType: e?.target?.value})
								}}
								defaultValue={data?.discountType}
								value={data?.discountType}>
								{items?.map((t) => <MenuItem
									sx={{fontSize: '14px'}}
									value={t?.value}>{t?.label}</MenuItem>)}
							</Select>
						</SelectControl>}

					</Box>
					<Box>
						<Typography sx={{
							color: '#848484',
							marginLeft: '10px',
							fontSize: '12px',
							fontWeight: '600',
							paddingBottom: '8px'
						}}>Amount</Typography>
						<AmountTextField
							margin="normal"
							value={data?.discount}
							sx={{fontSize: '14px', color: '#575A66'}}
							fullWidth
							onChange={(e) => {
								setData({...data, discount: e?.target?.value})
							}}
							name="amount"
							type="text"
						/>
					</Box>
				</Box>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderBottom: '1px dashed #E1E1E1',
					borderTop: '1px dashed #E1E1E1',
					paddingTop: '10px',
					paddingBottom: '10px',
					marginTop: '10px',
					marginLeft: {lg: '50px'}
				}}>
					<Typography sx={{
						color: '#575A66', fontWeight: '700', fontSize: '10px'
					}}>SubTotal:</Typography>
					<Typography sx={{
						color: '#575A66', fontWeight: '700', fontSize: '12px'
					}}>{getCurrentFormat(data).format(data?.oldPrice)}</Typography>
				</Box>

				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderBottom: '1px dashed #E1E1E1',
					paddingTop: '10px',
					paddingBottom: '10px',
					marginLeft: {lg: '50px'}
				}}>
					<Typography sx={{
						color: '#575A66', fontWeight: '700', fontSize: '10px'
					}}>DISCOUNT:</Typography>
					<Typography sx={{
						color: '#575A66', fontWeight: '700', fontSize: '12px'
					}}>{data?.discountType === '%' ? `${data?.discount}%` : getCurrentFormat(data).format(data?.discount)}</Typography>
				</Box>
				<TotalBox sx={{}}>
					<Typography sx={{
						color: '#FFFFFF', fontWeight: '700', fontSize: '15px'
					}}>TOTAL</Typography>
					<Typography sx={{
						color: '#FFFFFF', fontWeight: '700', fontSize: '20px'
					}}>{getCurrentFormat(data).format(data?.total)}</Typography>
				</TotalBox>
				<PriceButton
					onClick={onNext}
					type="button"
					fullWidth
					variant="contained">
					NEXT
				</PriceButton>
			</Grid>
		</Grid>
	</FlexContainerLg>)

}
export default Deliverables;
