import {Box, Grid, MenuItem, Select} from "@mui/material";
import React, {useEffect} from "react";
import CollapseRow from "./CollapseRow";
import Typography from "@mui/material/Typography";
import showError from "../utils/showError";
import localforage from "localforage";
import {getReq} from "../utils/rest";
import API from "../utils/api";
import {AmountTextField, MainContainer, PriceButton, SelectControl} from "./Cart.style";
import {useHistory} from "react-router-dom";
import {FlexContainerLg} from "./Common.styles";
import {getCurrentFormat} from "../utils/currency";
import calculatePrice from "../utils/priceCalculator";

const Cart = () => {
	const [activeCategory, setActiveCategory] = React.useState(null);
	const [data, setData] = React.useState({
		categoryId: null,
		total: 0,
		products: [],
		discount: null,
		discountType: 'Amount',
		oldPrice: 0,
		currency: 'USD',
		currencyList: []
	});

	const [items, setItems] = React.useState([]);
	const history = useHistory();

	useEffect(() => {
		getCurrencyList()
		getDiscounts()
		getFromDb()
		checkLogin()
	}, []);


	const checkLogin = async () => {
		const token = await localforage.getItem('token')
		if (!token) {
			logout()
		}
	}

	const logout = async () => {
		await localforage.removeItem('token')
		await localforage.removeItem('user')
		await localforage.removeItem('cart')
		history.push("/");
	}

	const getFromDb = async () => {
		const _data = await localforage.getItem('cart');
		if (_data) {
			setData(JSON.parse(_data))
		}
	}

	const getDiscounts = () => {
		getReq(API.discounts, false)
			.then((data) => {
				const _data = data?.data?.map((t) => {
					return {label: t.attributes.type, value: t.attributes.type}
				})
				setItems(_data)
			}).catch((e) => {
			if (e?.response?.status === 401) {
				logout();
			}
		})
	}

	const getCurrencyList = () => {
		getReq(API.currencies, false)
			.then(($data) => {
				setData({...data, currencyList: $data?.data})
			})
	}

	const onDiscountChanged = async () => {
		let _total;
		if (data?.discountType === '%') {
			_total = data.oldPrice - (data?.oldPrice * data?.discount / 100)
		} else {
			_total = data.oldPrice - data?.discount;
		}
		if (_total < 0) {
			_total = 0;
		}
		const _data = {...data, total: _total, discount: data?.discount, discountType: data?.discountType};
		setData(_data)
		await localforage.setItem('cart', JSON.stringify(_data))
	}

	useEffect(() => {
		if (data?.discountType != null) {
			onDiscountChanged();
		}
	}, [data?.discount, data?.discountType, data?.oldPrice, data?.currency, data?.currencyList])


	useEffect(() => {
		const updater = async () => {
			let products = data.products;
			let total = 0;
			for (const product of products) {
				const price = calculatePrice(product, data?.currency)
				total = total + (price * product?.count)
			}
			const _data = {
				...data, total: total, oldPrice: total, products: products
			};
			setData(_data)
			await localforage.setItem('cart', JSON.stringify(_data))
		}
		updater();
	}, [data?.currency])

	const calculatePricer = async (item, isIncrease) => {

		let products = data.products;
		if (data.products.filter((l) => l.id === item.id).length > 0) {
			products = data.products.map((i) => {
				if (i.id === item.id) {
					let _count = isIncrease ? i.count + 1 : i.count - 1
					if (_count < 0) {
						_count = 0;
					}
					return {
						id: item.id, count: _count, item: item
					}
				}
				return i;
			})
		} else {
			products.push({
				id: item.id, count: isIncrease ? 1 : 0, item: item
			})
		}

		let total = 0;
		for (const product of products) {
			const price = calculatePrice(product, data?.currency)
			total = total + (price * product?.count)
		}
		const _data = {
			...data, total: total, oldPrice: total, products: products
		};
		setData(_data)
		await localforage.setItem('cart', JSON.stringify(_data))
	}

	const onNext = () => {
		if (data?.products?.length === 0) {
			showError('You have to select a product')
			return;
		}
		history.push('/create-proposal/1')
	}


	return <FlexContainerLg>
		<MainContainer container spacing={4} maxWidth={'lg'}>
			<Grid item md={12} lg={6}>
				<CollapseRow activeCategory={activeCategory} setActiveCategory={setActiveCategory}
							 calculatePrice={calculatePricer}
							 data={data}
				/>
			</Grid>
			<Grid item md={0} lg={1}/>
			<Grid item lg={5} md={12} sx={{paddingLeft: {xs: '10px !important'}}}>
				<Box
					sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
					<Typography sx={{
						color: '#212226', fontWeight: '400', fontSize: '12px'
					}}>TOTAL PRICE</Typography>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '26px'
					}}>{getCurrentFormat(data).format(data?.total)}</Typography>
				</Box>

				<Box sx={{background: '#181818', padding: '30px 36px', borderRadius: '9px'}}>
					<Typography sx={{
						color: 'white', fontWeight: '700', fontSize: '18px'
					}}>Add Discount</Typography>
					<Box sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						marginTop: '20px',
						marginBottom: '40px'
					}}>
						<Box>
							<Typography
								sx={{color: '#848484', fontSize: '12px', fontWeight: '600', paddingBottom: '8px'}}>Discount
								type</Typography>
							<SelectControl fullWidth>
								<Select
									sx={{fontSize: '14px', color: '#575A66'}}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									onChange={(e) => {
										setData({...data, discountType: e?.target?.value})
									}}
									defaultValue={data?.discountType}
									value={data?.discountType}>
									{items?.map((t) => <MenuItem
										sx={{fontSize: '14px'}}
										value={t?.value}>{t?.label}</MenuItem>)}
								</Select>
							</SelectControl>
						</Box>
						<Box>
							<Typography sx={{
								color: '#848484',
								marginLeft: '10px',
								fontSize: '12px',
								fontWeight: '600',
								paddingBottom: '8px'
							}}>Amount</Typography>
							<AmountTextField
								margin="normal"
								value={data?.discount}
								sx={{fontSize: '14px', color: '#575A66'}}
								fullWidth
								onChange={(e) => {
									setData({...data, discount: e?.target?.value})
								}}
								name="amount"
								type="text"
							/>
						</Box>
						<Box sx={{marginLeft: '10px'}}>
							<Typography
								sx={{
									color: '#848484', fontSize: '12px', fontWeight: '600', paddingBottom: '8px'
								}}>Currency</Typography>
							<SelectControl fullWidth>
								<Select
									sx={{fontSize: '14px', color: '#575A66'}}
									labelId="demo-simple-select-label"
									id="demo-simple-select-curreny"
									onChange={(e) => {
										setData({...data, currency: e?.target?.value})
									}}
									defaultValue={data?.currency}
									value={data?.currency}>
									{data?.currencyList?.map((t) => <MenuItem
										sx={{fontSize: '14px'}}
										value={t?.attributes?.name}>{t?.attributes?.name}</MenuItem>)}
								</Select>
							</SelectControl>
						</Box>
					</Box>
					<Typography className={!data?.discount ? 'hidden' : ''} component={'span'} sx={{
						textDecorationLine: 'line-through',
						color: '#8B8B8B',
						fontWeight: '500',
						fontSize: '16px',
						background: '#383838',
						paddingLeft: '5px',
						paddingRight: '5px',
						borderRadius: '4px'
					}}>{getCurrentFormat(data).format(data?.oldPrice)}</Typography>

					<Typography sx={{
						color: 'white', fontWeight: '700', fontSize: '32px', marginTop: '5px'
					}}>{getCurrentFormat(data).format(data?.total)}</Typography>
				</Box>
				<PriceButton
					sx={{marginBottom: '30px'}}
					onClick={onNext}
					type="button"
					fullWidth
					variant="contained">
					CREATE PROPOSAL
				</PriceButton>
			</Grid>
		</MainContainer>
	</FlexContainerLg>
}
export default Cart;
