import React, {useEffect} from "react";
import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import {ListBox, MainBox, RightBox, SecondBox} from "./ProposalInformation.style";
import getFormattedTime from "../utils/dateTime";
import axios from "axios";
import API from "../utils/api";
import {FlexContainerLg} from "./Common.styles";
import {createAndSavePDF} from "../utils/pdf";
import {getCurrentFormat} from "../utils/currency";
import {Counter, CounterButton, Row} from "./CollapseRow.style";
import localforage from "localforage";
import calculatePrice from "../utils/priceCalculator";
import {AmountTextField, SelectControl} from "./Cart.style";
import {getReq, putReq} from "../utils/rest";
import CircularProgress from "@mui/material/CircularProgress";

const ProposalInformation = (props) => {
	const [vat, setVat] = React.useState(null);
	const [$data, $setData] = React.useState(null);
	const [data, setData] = React.useState(null);
	const [items, setItems] = React.useState([]);

	useEffect(() => {
		getDiscounts();
		getDetail();
		getVat()
	}, [])

	const getDetail = () => {
		getReq(`${API.proposal}${props?.id}`, true)
			.then((data) => {
				$setData(data?.data?.attributes)
				setData(data?.data?.attributes?.data)
			}).catch((e) => {
		})
	}

	const getDiscounts = () => {
		getReq(API.discounts, false)
			.then((data) => {
				const _data = data?.data?.map((t) => {
					return {label: t.attributes.type, value: t.attributes.type}
				})
				setItems(_data)
			})
	}

	const getVat = async () => {
		const response = await axios.get(API.vat);
		if (response.status === 200) {
			setVat(response.data.data[0]);
		}
	}

	const savePdf = () => {
		createAndSavePDF({attributes: $data}, vat,$data?.description, $data?.title ).then(r => {

		})
	}

	const updateProposal = (_data) => {
		putReq(API.proposal, props.id, {
			data: {
				...data,
				total: _data?.total?.toString(),
				discountType: _data?.discountType,
				discount: _data?.discount,
				oldPrice: _data?.oldPrice,
				products: _data?.products,
				data: _data
			}
		}, false)
			.then((e) => {
				getDetail();
			})
	}

	const calculatePricer = async (item, isIncrease) => {
		let products = data?.products;
		item = item?.item;
		if (products.filter((l) => l.id === item.id).length > 0) {
			products = products.map((i) => {
				if (i.id === item.id) {
					let _count = isIncrease ? i.count + 1 : i.count - 1
					if (_count < 0) {
						_count = 0;
					}
					return {
						id: item.id, count: _count, item: item
					}
				}
				return i;
			})
		} else {
			products.push({
				id: item.id, count: isIncrease ? 1 : 0, item: item
			})
		}

		let total = 0;
		for (const product of products) {
			const price = calculatePrice(product, data?.currency)
			total = total + ((price * product?.count))
		}

		const _data = {
			...data, total: total, oldPrice: total, products: products
		};
		setData(_data)
		await localforage.setItem('cart', JSON.stringify(_data))
	}


	const increase = (_item) => {
		calculatePricer(_item, true)
	}

	const decrease = (_item) => {
		calculatePricer(_item, false)
	}
	const onDiscountChanged = async () => {
		let _total;
		if (data?.discountType === '%') {
			_total = data.oldPrice - (data?.oldPrice * data?.discount / 100)
		} else {
			_total = data.oldPrice - data?.discount;
		}
		if (_total < 0) {
			_total = 0;
		}
		const _data = {...data, total: _total, discount: data?.discount, discountType: data?.discountType};
		setData(_data)
		await localforage.setItem('cart', JSON.stringify(_data))
		updateProposal(_data);
	}

	useEffect(() => {
		if (data?.discountType != null) {
			onDiscountChanged();
		}
	}, [data?.discount, data?.discountType, data?.oldPrice, data?.currency])


	const renderRow = (item) => {
		let count = 0;
		const $id = item?.id;
		const $oldObj = data?.products?.filter((t) => t.id === $id);
		if ($oldObj?.length > 0) {
			count = $oldObj[0].count
		}
		const title = item?.attributes?.title || item?.item?.item?.attributes?.title || item?.item?.attributes?.title;
		const description = item?.attributes?.description || item?.item?.item?.attributes?.description || item?.item?.attributes?.description;
		return <Row sx={{marginTop: '20px'}}>
			<div>
				<Typography sx={{fontWeight: '600', fontSize: '14px'}}>{title}</Typography>
				<Typography sx={{fontWeight: '500', fontSize: '14px', color: '#9e9d9f'}}>{description}</Typography>
			</div>
			<Row sx={{justifyContent: 'center'}}>
				<CounterButton className={'noselect'} onClick={() => decrease(item)}>
					<img width={'12px'}
						 src={require('../assets/remove.png')}/></CounterButton>
				<Counter className={'noselect'}>{count}</Counter>
				<CounterButton className={'noselect'} onClick={() => increase(item)}>
					<img width={'12px'}
						 src={require('../assets/add.png')}/></CounterButton>
			</Row>
		</Row>
	}


	let counter = 0;
	data?.products?.map((item) => {
		counter = counter + item?.count;
		return null
	})

	if(!data){
		return (<Box component={'span'} sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'100px'}}>
			<CircularProgress size="3.5rem"/>
		</Box>)
	}

	return (<FlexContainerLg sx={{marginTop: '100px'}}>
		<Grid container spacing={12} maxWidth={'lg'}>
			<Grid sx={{
				borderRight: {lg: '1px solid #E6E6E6'},
				marginTop: '20px',
				paddingTop: '30px !important',
				paddingRight: {
					lg: '70px'
				}
			}} item xs={12} md={7}>
				<MainBox>
					<SecondBox>
						<Box>
							<Typography sx={{
								fontWeight: '700', fontSize: '14px', color: '#212226'
							}}>{$data?.title}</Typography>
							<Typography sx={{
								fontWeight: '600', fontSize: '13px', color: '#212226'
							}}>{$data?.brand}</Typography>
							<Typography sx={{
								fontWeight: '500', fontSize: '14px', color: '#575A66'
							}}>{$data?.description}</Typography>
							<Box sx={{mt: '29px'}}>
								<Typography sx={{
									fontWeight: '500', fontSize: '9px', color: '#575A66'
								}}>{getFormattedTime($data?.createDate)}</Typography>
							</Box>
						</Box>
					</SecondBox>
				</MainBox>

				<Grid item md={12}>
					<Box sx={{
						display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '20px',
					}}>
						<Box>
							<Typography
								sx={{color: '#848484', fontSize: '12px', fontWeight: '600', paddingBottom: '8px'}}>Discount
								type</Typography>
							 <SelectControl fullWidth>
								<Select
									sx={{fontSize: '14px', color: '#575A66'}}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									onChange={(e) => {
										setData({...data, discountType: e?.target?.value})
									}}
									defaultValue={data?.discountType}
									value={data?.discountType}>
									{items?.map((t) => <MenuItem
										sx={{fontSize: '14px'}}
										value={t?.value}>{t?.label}</MenuItem>)}
								</Select>
							</SelectControl>

						</Box>
						<Box>
							<Typography sx={{
								color: '#848484',
								marginLeft: '10px',
								fontSize: '12px',
								fontWeight: '600',
								paddingBottom: '8px'
							}}>Amount</Typography>
							<AmountTextField
								margin="normal"
								value={data?.discount}
								sx={{fontSize: '14px', color: '#575A66'}}
								fullWidth
								onChange={(e) => {
									setData({...data, discount: e?.target?.value})
								}}
								name="amount"
								type="text"
							/>
						</Box>
					</Box>
					<Box sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						borderBottom: '1px dashed #E1E1E1',
						borderTop: '1px dashed #E1E1E1',
						paddingTop: '10px',
						paddingBottom: '10px',
						marginTop: '10px',
					}}>
						<Typography sx={{
							color: '#575A66', fontWeight: '700', fontSize: '10px'
						}}>SubTotal:</Typography>
						<Typography sx={{
							color: '#575A66', fontWeight: '700', fontSize: '12px'
						}}>{getCurrentFormat(data).format(data?.oldPrice)}</Typography>
					</Box>

					<Box sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						borderBottom: '1px dashed #E1E1E1',
						paddingTop: '10px',
						paddingBottom: '10px'
					}}>
						<Typography sx={{
							color: '#575A66', fontWeight: '700', fontSize: '10px'
						}}>DISCOUNT:</Typography>
						<Typography sx={{
							color: '#575A66', fontWeight: '700', fontSize: '12px'
						}}>{data?.discountType === '%' ? `${data?.discount}%` : getCurrentFormat(data).format(data?.discount)}</Typography>
					</Box>
					<Box sx={{
						flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', display: 'flex'
					}}>
						<Typography sx={{
							color: '#000000', fontWeight: '700', fontSize: '15px'
						}}>TOTAL</Typography>
						<Typography sx={{
							color: '#0000000', fontWeight: '700', fontSize: '20px'
						}}>{getCurrentFormat(data).format(data?.total)}</Typography>
					</Box>
				</Grid>

				<ListBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px'
					}}>Deliverables</Typography>
					<Typography sx={{
						position: 'relative', left: '4px', color: '#0037FF', fontWeight: '700', fontSize: '18px',
					}}>({counter})</Typography>
				</ListBox>
				{data?.products?.map((item) => {
					return renderRow(item);
				})}
			</Grid>
			<Grid item xs={12} md={5} sx={{
				marginTop: '10px', paddingTop: '0px !important', paddingLeft: {lg: '60px !important'}
			}}>
				<RightBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px'
					}}>Description</Typography>
				</RightBox>
				<Box sx={{typography: 'body2', mr: '80px', pt: '20px'}}>
					{vat?.attributes?.description}
				</Box>
				<Box sx={{mt: '80px'}}>
					<Box onClick={savePdf} sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						cursor: 'pointer',
						borderBottom: '1px solid #F0F0F0'
					}}>
						<Box component={'img'} src={"/img/visibility.png"} sx={{width: '18px', height: '14px'}}/>
						<Typography sx={{
							color: '#575A66', fontWeight: '600', fontSize: '12px', paddingLeft: '12px'
						}}>Download PDF</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	</FlexContainerLg>)


}

export default ProposalInformation;
