import {createTheme} from "@mui/material";

const theme = createTheme({
	typography: {
		fontFamily: [
			'Manrope',
			'Nunito',
			'BlinkMacSystemFont',
			'"Segoe UI"',
		].join(','),
	},
});


export default theme;
