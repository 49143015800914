import {Button, Grid, TextField} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";

export const HeadContainer = styled(Grid)(({theme}) => ({
	width: '100%', boxShadow: 'inset 0px -1px 0px #EDEEF2', padding: '10px'
}));
export const MenuList = styled(Grid)(({theme}) => ({
	textDecoration: 'none',
	color: '#575A66',
	marginRight: '20px',
	fontSize: '12px',
	fontWeight: '700',
	'@media (max-width: 567px)': {
		fontSize: '13px',
		marginRight: '20px'
	},
}));

export const SearchTextField = styled(TextField)(({theme}) => ({
	position: 'relative', backgroundColor: 'white', width: '900px', borderRadius: '30px', '& input': {
		height: '38px', paddingTop: 0, paddingBottom: 0
	}, '& > div:before': {
		borderBottom: '0 !important'
	}, '@media (max-width: 900px)': {
		width: '100%',
	}, '& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#c0c0c0',
		}, '&:hover fieldset': {
			borderColor: '#c0c0c0',
		}, '&.Mui-focused fieldset': {
			borderColor: '#c0c0c0',
		},
	},
}));

export const PriceButton = styled(Button)(({theme}) => ({
	width: '59px',
	height: '28px',
	borderRadius: '4px',
	position: 'absolute',
	right: '5px',
	fontSize: '9px',
	fontWeight: '700',
	top: '5px',
	background: '#0037FF',
	Padding: '0px, 50px, 0px, 50px'
}));
