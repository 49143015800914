import {Box, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MyBox, PriceButton, ProposalDescription, ProposalName, SelectControl} from "./Proposal.style";
import Slide from '@mui/material/Slide';
import axios from "axios";
import API from "../utils/api";
import {toast} from "react-toastify";
import showError from "../utils/showError";
import {useHistory} from "react-router-dom";
import {getReq} from "../utils/rest";
import PButton from "./PButton";
import {FlexContainerLg} from "./Common.styles";
import {createAndSavePDF} from "../utils/pdf";
import localforage from "localforage";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Proposal = () => {
	const history = useHistory();
	const [name, setName] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [showPopup, setShowPopup] = React.useState(0);
	const [isLoading, setLoading] = useState(false);
	const [brand, setBrand] = useState('');
	const [data, setData] = React.useState({categoryId: 0, total: 0, products: [], discount: null, oldPrice: 0, currency:'USD', currencyList:[]});
	const [profile, setProfile] = React.useState(null);
	const [email, setEmail] = React.useState('');
	const [vat, setVat] = React.useState(null);
	const [result, setResult] = React.useState(null);
	const [userId, setUserId] = React.useState(null);

	useEffect(() => {
		getFromDb();
		getVat()
	}, [])
	const getVat = async () => {
		const response = await axios.get(API.vat);
		if (response.status === 200) {
			setVat(response.data.data[0]);
		}
	}

	const getFromDb = async () => {
		const _data = await localforage.getItem('cart');
		if (_data) {
			setData(JSON.parse(_data))
		}
		const userStr = await localforage.getItem('user')
		if (userStr) {
			const userJSON = JSON.parse(userStr);
			setProfile(userJSON);
			setUserId(userJSON?.id)
		}
	}


	const saveProposal = async (newObject) => {
		const token = await localforage.getItem('token')
		setLoading(true)

		const _products = newObject?.products.map((e) => {
			return {"id": e?.id}
		})

		const requestObject = {
			"data": {
				"discount": data?.discount?.toString() || '',
				"title": name,
				"currency": data?.currency,
				"currencyList": data?.currencyList,
				"total": data?.oldPrice.toString(),
				"passkey": Math.random().toString(36).slice(-8),
				"description": description,
				"createDate": new Date().getTime().toString(),
				"brand": brand,
				"data": newObject,
				"user": {
					"id": userId
				},
				"products": _products
			}
		}

		axios.post(API.saveProposal, requestObject, {
			headers: {
				'Authorization': token
			}
		}).then((response) => {
			toast.success('Proposal has been saved')
			setLoading(false)
			setResult(response?.data?.data)
			setShowPopup(1)
		}).catch((e) => {
			setLoading(false)
			toast.error('An error occurred')
		})
	}

	const onSave = async () => {
		const newObject = {
			...data, description: description, brand: brand, title: name
		}

		if (description?.length < 1) {
			showError("You should fill the Description")
			return;
		}

		if (name?.length < 1) {
			showError("You should fill the Proposal Name")
			return;
		}

		if (brand === '') {
			showError("You should select the brand")
			return;
		}

		localforage.setItem('cart', JSON.stringify(newObject))
		await saveProposal(newObject);
	}

	const _setBrand = (e) => {
		setBrand(e())
	}

	const goProposal = () => {
		localforage.removeItem('cart')
		history.replace('/my-proposals')
	}
	const cancel = () => {
		history.replace('homepage');
	}

	const downloadPdf = () => {
		createAndSavePDF(result, vat, description, name).then(r => {
			//setShowPopup(3);
		})
	}

	const renderPopup = () => {
		return <Dialog
			sx={{
				borderRadius: '20px',

			}}
			open={showPopup === 1}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="alert-openRenderPopup">
			<DialogContent sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				minWidth: {lg: '375px', md: '320px'},
				paddingTop: '30px',
				paddingBottom: '40px'
			}}>
				<Box component={'img'} sx={{
					width: '40px', height: '50px'
				}} src="../img/verified_user.png"/>
				<Typography sx={{
					marginTop: '30px', fontSize: '26px', fontFamily: 'Manrope', fontWeight: '700'
				}}>Congratulations</Typography>
				<Typography sx={{
					width: '200px',
					marginTop: '12px',
					marginBottom: '22px',
					fontSize: '16px',
					lineHeight: '20px',
					fontFamily: 'Manrope',
					textAlign: 'center'
				}}>Your Proposal has been created successfully.</Typography>
				<PButton titleStyle={{fontSize: 12}} style={{width: 185, marginBottom: 20}}
						 onClick={() => downloadPdf()}>DOWNLOAD PDF</PButton>
				<Typography sx={{
					color: '#0037FF', fontSize: '12px', fontFamily: 'Manrope', fontWeight: '700', cursor: 'pointer'
				}}
							activeOpacity={9} onClick={goProposal}>Go to My Proposals</Typography>
			</DialogContent>
		</Dialog>
	}

	const sendViaEmail = () => {
		return <Dialog
			sx={{
				borderRadius: '20px',

			}}
			open={showPopup === 2}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="alert-openSendViaEmailPopup">
			<DialogContent sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				minWidth: '500px',
				paddingTop: '20px',
				paddingBottom: '40px'
			}}>
				<Typography sx={{
					marginTop: '30px', marginBottom: '10px', fontSize: '26px', fontFamily: 'Manrope', fontWeight: '700'
				}}>Send
					via E-Mail</Typography>
				<Box sx={{marginBottom: '20px'}}>
					<Typography sx={{
						marginBottom: '10px',
						marginLeft: '10px',
						fontFamily: 'Manrope',
						fontWeight: '600',
						color: '#575A66',
						fontSize: '12px'
					}}>Description</Typography>
					<TextField
						value={email}
						multiline={true}
						rows={5}
						onChange={(e) => setEmail(e?.target?.value)}
						sx={{
							width: '400px',
							height: '150px',
							color: '#575A66',
							backgroundColor: '#ffffff',
							padding: '5px',
							borderRadius: '7px',
							paddingLeft: '10px',
							borderWidth: '1px',
							borderColor: '#D0D1D6',
						}}
					/>
				</Box>
				<PButton sx={{width: '400px'}} activeOpacity={9}
						 onClick={cancel}>SEND</PButton>
				<Typography sx={{
					color: '#0037FF',
					fontSize: '12px',
					fontFamily: 'Manrope',
					fontWeight: '700',
					marginTop: '15px',
					cursor: 'pointer'
				}} activeOpacity={9} onClick={goProposal}>CANCEL</Typography>
			</DialogContent>
		</Dialog>
	}

	const successPopup = () => {

		return <Dialog
			sx={{
				borderRadius: '20px',

			}}
			open={showPopup === 3}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="alert-openSuccessPopup">
			<DialogContent sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				minWidth: '375px',
				paddingTop: '30px',
				paddingBottom: '40px'
			}}>
				<Box component={'img'} sx={{
					width: '40px', height: '50px'
				}} src="../img/verified_user.png"/>
				<Typography sx={{
					marginTop: '30px',
					fontSize: '26px',
					width: '200px',
					textAlign: 'center',
					lineHeight: '26px',
					fontFamily: 'Manrope',
					fontWeight: '700',
					marginBottom: '30px',
				}}>Your mail has been sent</Typography>
				<PButton titleStyle={{fontSize: 12}} style={{width: 185, marginBottom: 20}}
						 onClick={() => setShowPopup(2)}>CLOSE</PButton>
				<Typography sx={{
					color: '#0037FF', fontSize: '12px', cursor: 'pointer', fontFamily: 'Manrope', fontWeight: '700'
				}}
							activeOpacity={9} onClick={goProposal}>Go to My Proposals</Typography>
			</DialogContent>
		</Dialog>

	}

	return (<FlexContainerLg>
		<Grid container spacing={4} maxWidth={'lg'}>
			{sendViaEmail()}
			{successPopup()}
			{renderPopup()}
			<Grid item md={6}>
				<MyBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px'
					}}>Preview</Typography>
				</MyBox>
				<Box sx={{mt: 1, marginTop: '40px'}}>
					<Typography sx={{color: '#575A66', fontWeight: '600', fontSize: '12px'}} component="h3"
								variant="h6">
						Proposal Name
					</Typography>
					<ProposalName
						margin="normal"
						required
						fullWidth
						value={name}
						onChange={(e) => setName(e.target.value)}
						name="proposalNae"
						type="text"
						id="proposalNae"
					/>
					<Typography
						sx={{
							color: '#575A66',
							fontWeight: '600',
							fontSize: '12px',
							marginTop: '20px',
							paddingTop: '10px',
							paddingBottom: '10px'
						}}
						component="h3" variant="h6">Brand
					</Typography>
					<ProposalName
						margin="normal"
						required
						fullWidth
						value={brand}
						onChange={(e) => setBrand(e.target.value)}
						name="proposalNae"
						type="text"
						id="proposalNae"
					/>
					<Box sx={{marginTop: '40px'}}>
						<Typography sx={{color: '#575A66', fontWeight: '600', fontSize: '12px', marginTop: '20px'}}
									component="h3"
									variant="h6">
							Description
						</Typography>
					</Box>
					<ProposalDescription
						margin="normal"
						required
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						fullWidth
						name="description"
						type="text"
						id="description"
						multiline
						maxRows={8}
					/>
					<Box>
						<PriceButton
							onClick={onSave}
							type="button"
							fullWidth
							variant="contained">
							SAVE PROPOSAL
						</PriceButton>
					</Box>
				</Box>
			</Grid>
		</Grid>
	</FlexContainerLg>)
}

export default Proposal;
