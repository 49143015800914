import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

const JellyButton = styled(Button)(({theme}) => ({
	background: '#0037FF',
	height: '40px',
	color: 'white',
	fontFamily: 'Manrope',
	fontWeight: 700,
	fontSize: '12px',
	borderRadius: '4px',
	textTransform: 'none',
	boxShadow: 'none',
	' &:hover': {
		backgroundColor: '#0037FF',
		boxShadow: 'none'
	}
}));

export default JellyButton;
