import React, {useEffect} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getReq} from "../utils/rest";
import API from "../utils/api";
import {Box, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";

export const IconContainer = styled(Box)(({theme}) => ({
	backgroundColor: '#0037FF',
	width: '40px',
	height: '40px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '8px'
}));


export const Title = styled(Typography)(({theme}) => ({
	position: 'relative', left: '20px', top: '8px', fontWeight: '700', fontSize: '14px'
}));


export const CounterButton = styled(Typography)(({theme}) => ({
	backgroundColor: '#F7F7F7',
	borderRadius: '3px',
	cursor: 'pointer',
	width: '40px',
	height: '40px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}));

export const Counter = styled(Typography)(({theme}) => ({
	padding: '10px',
	backgroundColor: '#ffffff',
	borderRadius: '3px',
	fontSize: '14px',
	fontWeight: '600',
	width: '25px',
	textAlign: 'center',
	color:'#212226'
}));


export const Row = styled(Box)(({theme}) => ({
	display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
}));



export const MyAccordion = styled(Accordion)(({theme}) => ({
	'&:before':{
		height:'0'
	}
}));


