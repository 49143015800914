import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {InformationField, PasswordBox, PasswordField, PriceButton, FirstBox} from "./ProfileDetail.style";
import localforage from "localforage";
import showError from "../utils/showError";
import axios from "axios";
import API from "../utils/api";
import {toast} from "react-toastify";
import {FlexContainerLg} from "./Common.styles";

const ProfileDetail = () => {
	const [newPassword, setNewPassword] = React.useState('');
	const [newPassword2, setNewPassword2] = React.useState('');
	const [name, setName] = React.useState('');
	const [occupation, setOccupation] = React.useState('');

	useEffect(() => {
		readUserFromDb()
	}, [])

	const readUserFromDb = async () => {
		const userStr = await localforage.getItem('user')
		if (userStr) {
			const userJSON = JSON.parse(userStr);
			setName(userJSON?.username)
			setOccupation(userJSON?.title)
		}
	}

	const resetPassword = async () => {
		if (newPassword.length < 6) {
			showError('Your password should be longer than 5 characters')
			return false;
		}
		if (newPassword !== newPassword2) {
			showError('Your passwords do not match')
			return false;
		}
		await sendResetRequestToServer();
	}

	const sendResetRequestToServer = async () => {
		const token = await localforage.getItem('token')
		const userStr = await localforage.getItem('user')
		const email = JSON.parse(userStr.toString()).email;
		axios.post(API.password, {
			"identifier": email, "password": newPassword, "newPassword": newPassword
		}, {
			headers: {
				'Authorization': token
			}
		}).then((response) => {
			const _token = "Bearer " + response?.data?.jwt;
			localforage.setItem('token', _token)
			toast.success('Your password has been changed');
			setNewPassword('')
			setNewPassword2('')
		}).catch((e) => {
			showError('Please check your old password');
		})
	}

	return (<FlexContainerLg sx={{marginTop:'20px'}}>
		<Grid container spacing={4} maxWidth={'lg'}>
			<Grid item md={4} xs={12} >
				<FirstBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px'
					}}>Profile</Typography>
				</FirstBox>
				<Box sx={{mt: 1, marginTop: '20px'}}>
					<Typography sx={{color: '#575A66', fontWeight: '600', fontSize: '12px'}} component="h3"
								variant="h6">
						Name
					</Typography>
					<Box sx={{mb: '60px'}}>
						<InformationField
							margin="normal"
							required
							fullWidth
							name="name"
							value={name}
							type="text"
							id="password"
							autoComplete="current-password"
						/>
						<Box sx={{mt: '10px'}}>
							<Typography sx={{color: '#575A66', fontWeight: '600', fontSize: '12px'}} component="h3"
										variant="h6">
								Occupation
							</Typography>
						</Box>
						<InformationField
							margin="normal"
							required
							value={occupation}
							fullWidth
							name="description"
							type="text"
							id="description"
							multiline
							maxRows={8}
						/>
					</Box>
				</Box>
				<PasswordBox>
					<Typography sx={{
						color: '#212226', fontWeight: '700', fontSize: '18px'
					}}>Change Password</Typography>
				</PasswordBox>
				<Box >
					<Box sx={{mt: '20px'}}>
						<Typography sx={{color: '#575A66', fontWeight: '600', fontSize: '12px'}} component="h3"
									variant="h6">
							Password
						</Typography>
					</Box>
					<PasswordField
						margin="normal"
						required
						value={newPassword}
						onChange={(e) => setNewPassword(e.target?.value)}
						fullWidth
						name="password"
						type="text"
						id="password"
						autoComplete="current-password"
					/>
					<Box sx={{mt: '10px'}}>
						<Typography sx={{color: '#575A66', fontWeight: '600', fontSize: '12px'}} component="h3"
									variant="h6">
							Confirm Password
						</Typography>
					</Box>
					<PasswordField
						margin="normal"
						required
						fullWidth
						value={newPassword2}
						onChange={(e) => setNewPassword2(e.target?.value)}
						name="description"
						type="text"
						id="description"
						multiline
						maxRows={8}
					/>
				</Box>
				<PriceButton
					type="button"
					onClick={resetPassword}
					fullWidth
					variant="contained">
					APPLY
				</PriceButton>
			</Grid>
		</Grid>
	</FlexContainerLg>)

}
export default ProfileDetail;
