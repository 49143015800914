import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {LinkContainer, LoginBox, LoginText, LoginTextField, PriceButton} from "./LoginPage.styles";
import useLoginPage from "./useLoginPage";
import {createCDNFromStrapi} from "../utils/image";
import CircularProgress from "@mui/material/CircularProgress";

const LoginPage = () => {

	const {
		welcome, clickHandler, emailChanged, passwordChanged, email, password, mobileApps, isLoading
	} = useLoginPage();

	return (<Grid container>
		<Grid item xs={12} lg={12}>
			<LoginBox>
				<Box>
					<img width={'250px'} height={'90px'}
						 src={createCDNFromStrapi(welcome?.attributes?.image?.data?.attributes)} alt=""/>
				</Box>
				<LoginText component="h1" variant="h3" sx={{fontWeight: '700', fontSize: '36px'}}>
					{welcome?.attributes?.title}
				</LoginText>
				<LoginText component="h3" variant="h6"
						   sx={{fontWeight: '400', fontSize: '18px', marginTop: '10px', marginBottom: '20px'}}>
					{welcome?.attributes?.description}
				</LoginText>
				<Box component="div" noValidate sx={{mt: 1}}>
					<LoginTextField
						margin="normal"
						required
						value={email}
						onChange={emailChanged}
						fullWidth
						id="email"
						label="E-mail"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<LoginTextField
						margin="normal"
						required
						value={password}
						fullWidth
						onChange={passwordChanged}
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>
					<Grid container sx={{display: 'flex', justifyContent: 'flex-end'}}>
						<LinkContainer item>
							<Link to={'/forget-password'}>Forget password</Link>
						</LinkContainer>
					</Grid>
					<PriceButton
						onClick={clickHandler}
						type="button"
						fullWidth
						variant="contained">
						Login
					</PriceButton>
					{isLoading && <Box component={'span'} sx={{left: '20px', position: 'relative', top: '10px'}}>
						<CircularProgress size="1.5rem"/>
					</Box>}
					<Box sx={{textAlign: 'center'}}>
						<Box sx={{
							borderTop: '1px solid #D0D1D6',
							textAlign: 'center',
							marginTop: '45px',
							width: '250px',
							display: 'inline-block'
						}}>
							<Typography sx={{
								fontWeight: 'bold',
								fontSize: '14px',
								color: '#575A66',
								position: 'relative',
								top: '-12px',
								background: 'white',
								width: '130px',
								margin: '0 auto',
							}}>Click to Download</Typography>
						</Box>
					</Box>
					<Box component="div" noValidate
						 sx={{display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: 'center'}}>
						<Box sx={{marginRight: '15px'}}>
							{mobileApps && mobileApps?.length > 1 &&
								<a target={'_blank'} href={mobileApps[1]?.attributes?.name} id="text">
									<Box component={'img'} width={'118px'}
										 src={require('../assets/appstore.png')}/>
								</a>}
						</Box>
						<Box sx={{marginLeft: '20px'}}>
							{mobileApps && mobileApps?.length > 1 &&
								<a target={'_blank'} download href={mobileApps[0]?.attributes?.name}>
									<Box component={'img'} width={'135px'}
										 src={require('../assets/google-play.png')}/>
								</a>}
						</Box>
					</Box>
				</Box>
			</LoginBox>

		</Grid>


	</Grid>);
}

export default LoginPage;
