import {Box, Button} from "@mui/material";
import {styled} from "@mui/material/styles";
import React from "react";

export const HeadBox = styled(Box)(({theme}) => ({
	display: 'flex', justifyContent: 'flex-start', marginTop: '50px', borderBottom: '2px solid #E6E6E6',
}));

export const TotalBox = styled(Box)(({theme}) => ({
	background: '#181818',
	width: '366px',
	height: '55px',
	borderRadius: '6px',
	marginLeft: '50px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: '25px',
	paddingLeft: '20px',
	paddingRight: '20px',
	'@media (max-width: 557px)': {
		marginLeft: '-5px'
	},
}));

export const PriceButton = styled(Button)(({theme}) => ({
	fontSize: '14px',
	fontWeight: '700',
	width: '140px',
	height: '52px',
	marginTop: '50px',
	marginLeft: '50px',
	background: '#0037FF',
	'&:hover': {
		boxShadow: 'none', background: '#0037FF',
	},
	'@media (max-width: 557px)': {
		marginLeft: '0'
	},

}));
