import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import CollapseRow from "./CollapseRow";
import Typography from "@mui/material/Typography";
import showError from "../utils/showError";
import localforage from "localforage";
import {getReq} from "../utils/rest";
import API from "../utils/api";

export const MainContainer = styled(Grid)(({theme}) => ({
	marginTop: '30px', paddingLeft: '30px', paddingRight: '30px'
}));

export const AmountTextField = styled(TextField)(({theme}) => ({
	width: '107px',
	background: '#FFFFFF',
	marginLeft: '10px',
	marginTop: 0,
	marginBottom: 0,
	borderRadius: '4px',
	'& input': {
		height: '12px', borderRadius: '4px', color: '#575A66'
	}
}));

export const SelectControl = styled(FormControl)(({theme}) => ({
	width: '107px', background: '#FFFFFF', borderRadius: '4px', height: '44px', '& div': {
		height: '44px', borderRadius: '4px',

	}
}));

export const PriceButton = styled(Button)(({theme}) => ({
	width: '246px',
	height: '52px',
	borderRadius: '4px',
	padding: '0px 50px',
	marginTop: '20px',
	fontSize: '14px',
	fontWeight: '700',
	background: '#0037FF',
	'&:hover': {
		boxShadow: 'none', background: '#0037FF',
	}

}));
