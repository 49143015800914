import {Box, Container, Grid, InputAdornment} from "@mui/material";
import {Link, useHistory, useLocation} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import React, {useEffect} from "react";
import {HeadContainer, SearchTextField, MenuList, PriceButton} from "./Header.style";
import queryString from 'query-string'
import localforage from "localforage";
import {getReq} from "../utils/rest";
import API from "../utils/api";


const Header = () => {
	const history = useHistory();
	const {search} = useLocation()
	const values = queryString.parse(search)
	const [_search, setSearch] = React.useState(values.q || '')
	const [header, setHeader] = React.useState(null)

	const handleChange = (event) => {
		const val = event.target.value;
		setSearch(val);
		history.push('my-proposals?q=' + val)
	};

	useEffect(() => {
		getHeader()
	}, []);

	const getHeader = () => {
		getReq(API.header, false)
			.then((data) => {
				if (data?.data?.length > 0) {
					setHeader(data?.data[0]?.attributes)
				}
			})
	}

	const onFocusHandle = () => {

	}
	const onBlurHandle = () => {

	}

	const logout = async () => {
		await localforage.removeItem('token')
		await localforage.removeItem('user')
		await localforage.removeItem('cart')
		history.push("/");
	}

	return <Box sx={{background: '#FAFAFC', position:'relative', zIndex:99}}>
		<Container>
			<HeadContainer container>
				<Grid item lg={3} sx={{display: 'flex',}}>
					<Box mr={8}>
						<Link to={'/homepage'}>
							{header?.image &&
								<img className={'imgtop'} width={'105'} src={header?.image?.data?.attributes?.url} alt=""/>}
						</Link>
					</Box>
				</Grid>
				<Grid item lg={5} sx={{display: 'flex', marginTop: {xs: '20px', lg: '0'}}}>
					<Grid sx={{display: 'flex', alignItems: 'center'}}>
						<MenuList item component={Link} to={'/homepage'}>HOME</MenuList>
						<MenuList item component={Link} to={'/my-proposals'}>MY PROPOSAL</MenuList>
						<MenuList item component={Link} to={'/profile'}>PROFILE</MenuList>
						<MenuList onClick={logout} item component={Link} to={'/#logout'}>LOGOUT</MenuList>
					</Grid>
				</Grid>
				<Grid item lg={4} sx={{display: {xs: 'none', md: 'none', lg: 'flex'}}}>
					<SearchTextField
						autoFocus={values?.q?.length > 0}
						id={'user-search'}
						InputProps={{
							startAdornment: (<InputAdornment position="start">
								<SearchIcon/>
							</InputAdornment>),
							endAdornment: (<PriceButton variant="contained">SEARCH</PriceButton>),
							readOnly: false
						}}
						onFocus={onFocusHandle}
						value={_search}
						readOnly={false}
						autoComplete='off'
						onBlur={onBlurHandle}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						name="search"
					/>

				</Grid>
			</HeadContainer>
		</Container>
	</Box>
}

export default Header;
