import React from "react";
import Header from "../components/Header";
import ProposalInformation from "../components/ProposalInformation";
import {useParams} from "react-router-dom";

const CreateProposal = () => {
	let {id} = useParams();
	return (<>
		<Header/>
		<ProposalInformation id={parseInt(id)}/>
	</>);
}
export default CreateProposal;
