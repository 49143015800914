import {Box, Button, Grid, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import React from "react";

export const ListBox = styled(Box)(({theme}) => ({
	display: 'flex', marginTop: '30px', borderBottom: '2px solid #E6E6E6', paddingBottom: '20px',
}));
export const MainBox = styled(Box)(({theme}) => ({
	display: 'flex', justifyContent: 'space-between',
	mt: '20px'
}));
export const SecondBox = styled(Box)(({theme}) => ({
	display: 'flex', flexDirection: 'row'
}));
