import React from "react";
import {Box, Button, FormControl, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";

export const MyBox = styled(Box)(({theme}) => ({
    display: 'flex', marginTop: '50px', borderBottom: '2px solid #E6E6E6', paddingBottom: '20px',
}));

export const SelectControl = styled(FormControl)(({theme}) => ({
    width: '405px',
    height: '52px',
    background: '#FFFFFF',
    borderRadius: '4px',
    mt: '20px',
    borderBottom: 'none',
    '& .MuiInput-underline:after': {
        borderBottomColor: '#D0D1D6',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#D0D1D6',
        }, '&:hover fieldset': {
            borderColor: '#D0D1D6',
        }, '&.Mui-focused fieldset': {
            borderColor: '#D0D1D6',
        },
    }
}));
export const PriceButton = styled(Button)(({theme}) => ({
    width: '226px', height: '52px', marginLeft: '5px', marginTop: '40px', background: '#0037FF', '&:hover': {
        boxShadow: 'none', background: '#0037FF',
    }
}));

export const ProposalName = styled(TextField)(({theme}) => {
    return {
        width: '405px',
        height: '52px',
		marginTop:'10px',
        background: '#FFFFFF',
        borderRadius: '4px',
        borderBottom: 'none',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#D0D1D6',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D0D1D6',
            }, '&:hover fieldset': {
                borderColor: '#D0D1D6',
            }, '&.Mui-focused fieldset': {
                borderColor: '#D0D1D6',
            },
        }
    }
});

export const ProposalDescription = styled(TextField)(({theme}) => ({
    width: '405px', background: '#FFFFFF', mb: '10px',
    '& .MuiInput-underline:after': {
        borderBottomColor: '#D0D1D6',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#D0D1D6',
        }, '&:hover fieldset': {
            borderColor: '#D0D1D6',
        }, '&.Mui-focused fieldset': {
            borderColor: '#D0D1D6',
        },
    },
    '& textarea': {
        height: '150px !important',
        borderRadius: '4px',
        borderBottom: 'none',
    }
}));
