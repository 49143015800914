import React from "react";
import Header from "../components/Header";
import Proposal from "../components/Proposal";

const CreateProposalSecond = () => {
    return (<>
        <Header/>
        <Proposal/>
    </>);
}

export default CreateProposalSecond;