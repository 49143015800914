import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import axios from "axios";
import API from "../utils/api";
import {toast} from "react-toastify";
import {PriceButton} from "./LoginPage.styles";

const LoginTextField = styled(TextField)(({theme}) => ({
	'@media (max-width: 550px)': {
		marginTop: '20px'
	}, ' & fieldset': {
		border: '1px solid #44A3B5', boxSizing: 'border-box', borderRadius: '4px',
	}
}));

const LoginBox = styled(Box)(({theme}) => ({
	padding: '15px',
	marginTop: '100px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	borderRadius: 30,
	'@media (max-width: 550px)': {
		marginTop: '20px'
	}
}));

const LoginText = styled(Typography)(({theme}) => ({}));

const ResetPage = () => {

	const [email, setEmail] = React.useState('');
	const [forgetPassword, setForgetPassword] = useState(null);

	useEffect(() => {
		getForgetPassword()
	}, []);

	const getForgetPassword = async () => {
		const response = await axios.get(API.forget);
		if (response.status === 200) {
			setForgetPassword(response.data.data[0]);
		}
	}
	const resetPassword = () => {
		axios.post(API.forgetPassword, {
			email: email
		}).then((response) => {
			if (response?.status === 200) {
				setEmail('')
				toast.success('Your password reset email has been sent.')
			} else {
				toast.error('Email is not correct')
			}
		}).catch(() => {
			toast.error('Email is not correct')
		})
	}

	const onEmailChanged = (event) => {
		setEmail(event.target.value)
	}

	return (<Grid container>
			<Grid item xs={12} lg={12}>
				<LoginBox>
					<Box>
						<img width={'100%'} height={'90px'} src={require('../assets/price.png')} alt=""/>
					</Box>
					<LoginText component="h1" variant="h3" sx={{fontWeight: '700', fontSize: '36px'}}>
						{forgetPassword?.attributes?.title}
					</LoginText>
					<LoginText component="h3" variant="h6" sx={{fontWeight: '400', fontSize: '18px', marginTop: '10px', marginBottom: '20px'}}>
						{forgetPassword?.attributes?.description}
					</LoginText>
					<Box component="div" noValidate sx={{mt: '15px'}}>
						<LoginTextField
							margin="normal"
							value={email}
							onChange={onEmailChanged}
							fullWidth
							id="email"
							label="E-mail"
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<PriceButton onClick={resetPassword} sx={{width: '150px', height: '50px', marginTop: '20px'}}
									 type="button"
									 fullWidth
									 variant="contained">
							RESET
						</PriceButton>
					</Box>
				</LoginBox>
			</Grid>
		</Grid>);
}

export default ResetPage;
