import React from "react";
import Header from "../components/Header";
import Cart from "../components/Cart";

const HomePage = () => {
    return (<>
        <Header/>
        <Cart/>
    </>);
}

export default HomePage;
