const calculatePrice = (product, $currency) => {
	let currency = $currency || "USD"
	let price;

	if (currency === 'TRY') {
		const creativeManHour = product?.item?.attributes?.creativeManHourTRY || 0
		const manHour = product?.item?.attributes?.manHourTRY || 1
		const productionManHour = product?.item?.attributes?.productionManHourTRY || 0
		price = ((creativeManHour * manHour) + (productionManHour * manHour));
	} else {
		const creativeManHour = product?.item?.attributes?.creativeManHour || 0
		const manHour = product?.item?.attributes?.manHour || 1
		const productionManHour = product?.item?.attributes?.productionManHour || 0


		price = ((creativeManHour * manHour) + (productionManHour * manHour));
	}
	return price;
}

export default calculatePrice
