import showError from "./showError";
import {jsPDF} from "jspdf";
import {getCurrentFormat} from "./currency";
import calculatePrice from "./priceCalculator";

const createHTML = (data, vat, description, name) => {
	let products = data?.attributes?.data?.products?.map((product) => {
		const price = calculatePrice(product, data?.attributes?.currency)
		return `<div class="row">
         <div class="column1">
          <div class="deliverable-title">${product?.item?.attributes?.title}
          <span class="pcs">(${product?.count}pcs)</span>
          </div>

		  <div class="deliverable-description">
		  	${product?.item?.attributes?.description}
		  </div>

		 </div>
        	<div class="column2">
        	<span class="price-text">${getCurrentFormat(data).format(price)}</span>
          </div>
        </div>`

	}).join("")

	const discount = data?.attributes?.data?.discountType === '%'
		? `${data?.attributes?.data?.discount}%`
		: getCurrentFormat(data).format(data?.attributes?.data?.discount);

	return `<div>
       <style>
            body {
                font-size: 16px;
                color: #000000;
                font-family: Helvetica;
                padding-left: 30px;
                padding-right: 30px;
            }
            h1 {
                text-align: center;
            }

            .brand{
            color:white;
            padding-left: 60px;
            font-weight: bolder;
            text-transform: uppercase;
            font-size: 22px;
            float: left;
            }

            .brand-img-wrapper{
               text-align: center;
            }

            .details{
              background: #f0f0f0;
              padding: 30px 80px 50px;
            }

             .details2{
              background: #ffffff;
              padding: 30px 80px 0;
            }

            .products{

            }
            .deliverables{
                font-weight: bold;
            }

            .header{
            	background: #0335fd;
            	width: 100%;
            	height: 60px;
            	line-height: 55px;
            	z-index: 0;
            }

            .header:after{
              	content: "";
  				clear: both;
  				display: table;
            }

            .content{
            	padding:60px;
            }


            .total{
                font-weight: bold;
                margin-top: 20px;
            }

            .pcs{
             color:#21379b;
            }

            .deliverable-description{
              color:#acacac;
              font-size: 15px;
              margin-top: 5px;
            }

            .td5{
            width: 100px;
            }

            .detail-title{
            	font-weight: bold;
            	font-size: 20px;
            }

            .row{
            	margin-bottom: 30px;
            	padding-left: 15px;
            	padding-right: 15px;
            	 border-bottom: 1px solid #eaeaea;
            	 padding-bottom: 20px;
            }

            .row:after,.list-wrapper:after{
            	 content: "";
  				clear: both;
  				display: table;
            }

            .column1{
              float: left;
              width: 75%;
            }

            .column2{
              float: left;
              width: 20%;
              margin-left: 5%;
            }

            .deliverable-title{
          		 font-weight: 500;
            }

            .mt20{
            margin-top: 20px;
            }

            .list-wrapper{
            background: #f5f5f5;
            padding: 20px;
            border-bottom: 1px solid #eaeaea;
            }

            .price-text{
            	position: relative;
            	top:20px;
            	font-weight: bold;
            }

            .img-logo{
            display: block;
            float: right;
            position: relative;
            right: 60px;
            top:4px;
            }

            .total-txt{
            width: 80%;
            float: left;
            font-size: 13px;
            }

            .list-txt{
            width: 20%;
            font-size: 15px;
            float: left;
            }

            .list-txt-final{
            font-size: 20px;
            }

            .detail-txt{
            color:#838387;
            line-height: 24px;
            }

            .total-box{
            	margin-top: 30px;
            	border-radius: 10px;
            	overflow: hidden;
            }

            .total-price-wrapper{
            	background: #0137ff!important;
            }

            .total-price-wrapper .total-txt, .total-price-wrapper .list-txt{
              color: white;
            }

        </style>

		<!--Header Start-->
		<div class="header">
			<span class="brand">${data?.attributes?.brand}</span>
			<img class="img-logo" width="150" src="${window.location.origin}/img/pdflogo.png" />
		</div>
		<!--Header End-->

		<div class="details2">
			<h2 class="detail-title">${name}</h2>
		 	<span class="detail-txt">${description}</span>
		</div>

		<!--Content Start-->
		<div class="content">


		<!--Row Start-->
		${products}
		<!-- Row End-->

		<div class="total-box">
		<div class="list-wrapper">
			<div class="total-txt">Subtotal</div>
			<div class="list-txt">${getCurrentFormat(data).format(data?.attributes?.data?.oldPrice)}</div>
		</div>
		<div class="list-wrapper">
			<div class="total-txt">Discount</div>
			<div class="list-txt">${discount}</div>
		</div>
		<div class="list-wrapper total-price-wrapper">
			<div class="total-txt">TOTAL</div>
			<div class="list-txt list-txt-final">${getCurrentFormat(data).format(data?.attributes?.data?.total)}</div>
		</div>
		</div>

		</div>
		<!-- Content End-->

		<div class="details">
			<h2 class="detail-title">VAT</h2>
		 	<span class="detail-txt">${vat?.attributes?.description}</span>
		</div>

	</div>
`;
}


const createAndSavePDF = async (object, vat, description = 'Description', name = 'Sharpcake Offer') => {
	var canvas = document.createElement('canvas');
	canvas.width = 840;
	canvas.height = 840;

	var options = {
		canvas: canvas, scale: 1, width: 840, height: 840, windowHeight: 840, windowWidth: 840,
	};

	try {
		let html = createHTML(object, vat, description, name);
		const doc = new jsPDF(options);
		doc.setFont("Helvetica");
		const width = doc.internal.pageSize.getWidth();
		doc.html(html, {
			callback: function (doc) {
				doc.save('offer.pdf');
			}, x: 0, y: 0, width: width, windowWidth: options.windowWidth
		});
	} catch (error) {
		showError('An error occurred')
	}
};


export {
	createAndSavePDF
}
